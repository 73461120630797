// 
// page 
// 

import React from "react";
import MSHeaderSample from "components/MSHeaderSample";
import MSLayout from "components/MSLayout"; // highlight-line

export default function About() {
    return (
        <MSLayout>
            <div style={{ color: `teal` }}>
                <MSHeaderSample headerText="Header" />
                <h1>About Gatsby</h1>
                <p>Such wow. Very React.</p>
            </div>
        </MSLayout>
    );
}