// 
// component
// 

import React from "react"
import { Link } from "gatsby"

export default function MSHeaderSample(props) {
  return (
    <div>
      <h1>{props.headerText}</h1>
      <ul>
        <li><Link to="#xxx">XXX</Link></li>
        <li><Link to="#yyy">YYY</Link></li>
        <li><Link to="#zzz">ZZZ</Link></li>
      </ul>
    </div>
  )
}